import React from 'react'
import PropTypes from 'prop-types'

import andreas from '../images/andreas-mueller-white.jpg'
import philipp from '../images/ps.jpg'
import claudia from '../images/cm.jpg'

// import bg03 from '../images/bg03.jpg'

// import ban_feuer from '../images/banner/feuer.png'
// import ban_holz from '../images/banner/holz.png'
import ban_kamin from '../images/banner/kamin.png'
// import ban_security1 from '../images/banner/security.png'
import ban_security2 from '../images/banner/security2.png'
import ban_sunset1 from '../images/banner/sonnenuntergang.png'
// import ban_sunset2 from '../images/banner/sonnenuntergang1.png'
// import ban_storch from '../images/banner/storch.png'
// import ban_team from '../images/banner/team.png'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>
    const leistungen = [
      'Kehren und reinigen von Schornsteinen und Abgasleitungen',
      'Reinigung der Rauchrohre von sämtlichen Feuerstätten',
      'Abgasmessungen an Feuerstätten von festen, flüssigen und gasförmigen Brennstoffen mit modernsten Abgasmessgeräten',
      'Abgaswegeüberprüfungen an Öl und Gasfeuerstätten',
      'Überprüfung der erforderlichen Verbrennungsluftversorgung',
      'Kontrolle neu errichteter Feuerungsanlagen auf Einhaltung der Betriebs- und Brandsicherheit',
      'Erfassen von Daten an Feuerungsanlagen für Maßnahmen der Energieeinsparung',
      'Datenerfassung für Emmissionskataster',
      'Vertrieb von Rauchwarnmeldern (Ei Electronics)',
      'Dichtheitsprüfung an Abgasanlagen',
      'Gashausschau',
      'Holzfeuchtemessung',
      'Schornsteinquerschnittsberechnungen nach DIN EN 13384',
      'Reinigung von Kachelöfen mit Heizeinsätzen samt Rauchrohren',
      'Reinigung von Heizkesseln mit Abgasrohren',
    ]
    /*
    const articles = [{
      id: 'leistungen',
      title: 'Leistungen',
      img: pic01,
      alt: '',
      contents: [
        () => this.renderList(leistungen),
      ],
    },{
      id: 'team',
      title: 'Das Team',
      img: pic01,
      alt: '',
      contents: [
        () => <p>Aenean ornare velit lacus, ac varius enim ullamcorper eu. Proin aliquam facilisis ante interdum congue. Integer mollis, nisl amet convallis, porttitor magna ullamcorper, amet egestas mauris. Ut magna finibus nisi nec lacinia. Nam maximus erat id euismod egestas. By the way, check out my <a href="#work">awesome work</a>.</p>
      ],
    },{
      id: 'impressum',
      title: 'Impressum',
      img: pic01,
      alt: '',
      contents: [
        'Aenean ornare velit lacus, ac varius enim ullamcorper eu. Proin aliquam facilisis ante interdum congue. Integer mollis, nisl amet convallis, porttitor magna ullamcorper, amet egestas mauris. Ut magna finibus nisi nec lacinia. Nam maximus erat id euismod egestas. By the way, check out my <a href="#work">awesome work</a>.',
      ],
    },{
      id: 'datenschutz',
      title: 'Datenschutz',
      img: pic01,
      alt: '',
      contents: [
        'Aenean ornare velit lacus, ac varius enim ullamcorper eu. Proin aliquam facilisis ante interdum congue. Integer mollis, nisl amet convallis, porttitor magna ullamcorper, amet egestas mauris. Ut magna finibus nisi nec lacinia. Nam maximus erat id euismod egestas. By the way, check out my <a href="#work">awesome work</a>.',
      ],
    }]
    */

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>
        {/*articles.map(({ id, title, img, contents, alt }) => {
          return (
            <article key={id} id={id} className={`${this.props.article === id ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
              <h2 className="major">{title}</h2>
              {!!img && <span className="image main"><img src={img} alt={alt} /></span>}
              {contents.map((__html) => this.renderContent(__html))}
              {close}
            </article>
          )
        })*/}
        <article id="leistungen" className={`${this.props.article === 'leistungen' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Leistungen</h2>
          {<span className="image main"><img src={ban_sunset1} alt="" /></span>}
          {this.renderList(leistungen)}
          {close}
        </article>
        <article id="team" className={`${this.props.article === 'team' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Das Team</h2>
          {/*<span className="image main"><img src={ban_team} alt="" /></span>*/}
          <div className="person-wrap">
            <div className="person">
              <img src={andreas} className="image left" alt="" />
              <h3>Andreas Müller</h3>
              <h4>Schornsteinfegermeister</h4>
              <p>Im Juli 1976 begann meine Lehre zum Schornsteinfeger im elterlichen Betrieb. 1979 legte ich in Ulm die Gesellenprüfung ab. Nach vierjähriger Gesellenzeit habe ich 1982 vor der Meisterprüfungskommission in Ulm die Meisterprüfung mit Erfolg abgelegt. 1992 wurde ich auf den Kehrbezirk Zollernalbkreis Nr. 20 bestellt. Nach 8 1/2 jähriger Verwaltung dieses Kehrbezirks, lies ich mich im Herbst 1998 auf den Kehrbezirk Zollernalbkreis Nr. 8 versetzen, den ich bis zum heutigen Tage verwalte.</p>
            </div>
            <hr />
            <div className="person">
              <img src={philipp} className="image left" alt="" />
              <h3>Philipp Schaal</h3>
              <h4>Schornsteinfeger</h4>
              <p>...ist seit April 2012 als Schornsteinfegergeselle in unserem Betrieb. Er ist mit allen zu unserem Handwerk gehörigen Aufgaben vertraut.</p>
            </div>
            <hr />
            <div className="person">
              <img src={claudia} className="image left" alt="" />
              <h3>Claudia Müller</h3>
              <h4>Büro</h4>
              <p>Meine Frau Claudia Müller ist für die Büroorganisation zuständig. Sie ist Ansprechpartnerin für Ihre Terminwünsche und -absprachen.</p>
            </div>
          </div>
          {close}
        </article>

        <article id="impressum" className={`${this.props.article === 'impressum' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Impressum</h2>
          {<span className="image main"><img src={ban_kamin} alt="" /></span>}
					<h3>Angaben gemäß § 5 TMG</h3>
					<p>
            Andreas Müller<br/>
            Schornsteinfegermeister<br/>
            Eugenstraße 29<br/>
            72475 Bitz
          </p>
          <h3>Kontakt</h3>
          <p>
            Telefon: +49 (0) 7431 81655<br/>
            Telefax: +49 (0) 7431 82254<br/>
            Mobil: +49 (0) 172 7467257<br/>
            E-Mail: <a href="mailto:andreas@mueller-schornsteinfeger.de">andreas@mueller-schornsteinfeger.de</a>
          </p>
          <h3>Berufsbezeichnung und berufsrechtliche Regelungen</h3>
          <p>
            Berufsbezeichnung:<br/>Schornsteinfeger<br/>
            Zuständige Kammer:<br/>Handwerkskammer Reutlingen<br/>
          </p>
          <p>
            Es gelten folgende berufsrechtliche Regelungen: Gesetz über das Berufsrecht und die Versorgung im Schornsteinfegerhandwerk
          </p>
          <p>
            Regelungen einsehbar unter:<br/><a href="https://www.gesetze-im-internet.de/schfhwg/" target="_blank" rel="noopener noreferrer">https://www.gesetze-im-internet.de/schfhwg/</a>
          </p>
          <h3>Aufsichtsbehörde</h3>
          <p>
            Landratsamt ZAK<br />
            Hirschbergstraße 29<br/>
            72336 Balingen
            </p>
          <h3>Angaben zur Berufshaftpflichtversicherung</h3>
          <p>
            Name und Sitz des Versicherers:<br/>
            VHV Allgemeine Versicherung AG<br/>
            VHV-Platz 1<br/>
            30177 Hannover
          </p>
          <p>
            Geltungsraum der Versicherung:<br/>Deutschland
          </p>

          <h3>Streitschlichtung</h3>
          <p>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:<br/>
            <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a><br/>
            Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>
          <p>
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
          </p>

          <h3>Haftung für Inhalte</h3>
          <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zuforschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
          <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
          
          <h3>Haftung für Links</h3>
          <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhaltewaren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
          <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
          
          <h3>Urheberrecht</h3>
          <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
          <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
					{close}
        </article>

        <article id="datenschutz" className={`${this.props.article === 'datenschutz' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Datenschutz</h2>
          {<span className="image main"><img src={ban_security2} alt="" /></span>}
          
          <h3>1. Datenschutz auf einen Blick</h3>
          <h4>Allgemeine Hinweise</h4>
          <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
          
          <h4>Datenerfassung auf unserer Website</h4>
          <h5>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h5>
          <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.</p>
          
          <h5>Wie erfassen wir Ihre Daten?</h5>
          <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
          <p>Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.</p>
          
          <h5>Wofür nutzen wir Ihre Daten?</h5>
          <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
          
          <h5>Welche Rechte haben Sie bezüglich Ihrer Daten?</h5>
          <p>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
          <p>Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung unter „Recht auf Einschränkung der Verarbeitung“.</p>
          
          <h5>Analyse-Tools und Tools von Drittanbietern</h5>
          <p>Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie in der folgenden Datenschutzerklärung.</p>
          <p>Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung informieren.</p>
          
          <h3>2. Allgemeine Hinweise und Pflichtinformationen</h3>
          <h4>Datenschutz</h4>
          <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
          <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.</p>
          <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
          
          <h4>Hinweis zur verantwortlichen Stelle</h4>
          <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
          <p>
            Andreas Müller<br/>
            Eugenstr. 29<br/>
            72475 Bitz
          </p>
          <p>
            Telefon: +49 (0) 7431 81655<br/>
            E-Mail: datenschutz@mueller-schornsteinfeger.de
          </p>
          <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.</p>
          
          <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
          <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
          
          <h4>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</h4>
          <p>Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).</p>
          
          <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>
          <p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
          
          <h4>Recht auf Datenübertragbarkeit</h4>
          <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

          <h4>SSL- bzw. TLS-Verschlüsselung</h4>
          <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
          <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>

          <h4>Auskunft, Sperrung, Löschung und Berichtigung</h4>
          <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p>
          
          <h4>Recht auf Einschränkung der Verarbeitung</h4>
          <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:</p>
          <ul>
            <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah / geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
            <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
          </ul>
          <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten - von ihrer Speicherung abgesehen - nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.</p>

          <h4>Widerspruch gegen Werbe-E-Mails</h4>
          <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>


          <h3>3. Datenerfassung auf unserer Website</h3>
          <h4>Cookies</h4>
          <p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.</p>
          <p>Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</p>
          <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.</p>
          <p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.</p>

          <h4>Kontaktformular</h4>
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
            Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage
            und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
            Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf
            Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen.
            Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </p>
          <p>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
            (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen -
            insbesondere Aufbewahrungsfristen - bleiben unberührt.
          </p>

          <h4>Server-Log-Dateien</h4>
          <p>
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so
            genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt.<br/>
            Dies sind:
          </p>
          <ul>
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP-Adresse</li>
          </ul>
          <p>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
            vorgenommen.<br/>
            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
            ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website -
            hierzu müssen die Server-Log-Files erfasst werden.
          </p>

          <h4>Anfrage per E-Mail, Telefon oder Telefax</h4>
          <p>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
            hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens
            bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
            DSGVO) und / oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein berechtigtes
            Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen haben.
          </p>
          <p>
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
            (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen -
            insbesondere gesetzliche Aufbewahrungsfristen - bleiben unberührt.
          </p>

          <h3>4. Analyse-Tools und Werbung</h3>
          <h4>Matomo (ehemals Piwik)</h4>
          <p>
            Diese Website benutzt den Open Source Webanalysedienst Matomo. Matomo verwendet so genannte
            "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der
            Benutzung der Website durch Sie ermöglichen. Dazu werden die durch den Cookie erzeugten Informationen
            über die Benutzung dieser Website auf unserem Server gespeichert. Die IP-Adresse wird vor der
            Speicherung anonymisiert.
          </p>
          <p>
            Matomo-Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen.
          </p>
          <p>
            Die Speicherung von Matomo-Cookies und die Nutzung dieses Analyse-Tools erfolgen auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der anonymisierten
            Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.
          </p>
          <p>
            Die durch den Cookie erzeugten Informationen über die Benutzung dieser Website werden nicht an Dritte
            weitergegeben. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
            Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
            sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
          </p>
          <p>
            Wenn Sie mit der Speicherung und Nutzung Ihrer Daten nicht einverstanden sind, können Sie die
            Speicherung und Nutzung hier deaktivieren. In diesem Fall wird in Ihrem Browser ein Opt-Out-Cookie
            hinterlegt, der verhindert, dass Matomo Nutzungsdaten speichert. Wenn Sie Ihre Cookies löschen, hat dies
            zur Folge, dass auch das Matomo Opt-Out-Cookie gelöscht wird. Das Opt-Out muss bei einem erneuten
            Besuch unserer Seite wieder aktiviert werden.
          </p>
          <p>
            <iframe
              title="matomo-opt-out"
              className="matomo-opt-out"
              src="https://mueller-schornsteinfeger.de/analytics/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=ffffff&fontSize=&fontFamily="
              ></iframe>
          </p>

          <h3>5. Plugins und Tools</h3>
          <h4>Google Web Fonts</h4>
          <p>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
            bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von Google findet
            dabei nicht statt.
          </p>

          {close}
        </article>
      </div>
    )
  }

  renderContent(content) {
    if (typeof content === 'function') {
      return content();
    }
    const __html = typeof content === 'object' ? content.html : content
    const Tag = typeof content === 'object' ? content.tag || 'p' : 'p'
    const args = typeof content === 'object' ? content.args || {} : 'p'
    return <Tag dangerouslySetInnerHTML={{ __html }} {...args}></Tag>
  }

  renderList(list) {
    let i = 0;
    return (
      <ul>
        {list.map((entry) => {
          return this.renderContent({ tag: 'li', html: entry, args: { key: `li-${i++}` } })
        })}
      </ul>
    )
  }

}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main