import React from 'react'
import PropTypes from 'prop-types'
import icon from '../images/andreas-mueller-white.jpg'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <img src={icon} width="100%" height="100%" alt="" />
        </div>
        <div className="content">
            <div className="inner">
                <h1>Herzlich Willkommen</h1>
                <p>Auf der Homepage des Schornsteinfegermeisters Andreas Müller.</p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('leistungen')}}>Leistungen</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('team')}}>Team</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('impressum')}}>Impressum</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('datenschutz')}}>Datenschutz</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
